import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Form.css';
import { useConnectionContext } from './ConnectionContext';
import { ProgressBar } from 'react-bootstrap';
import { useLayoutContext } from './LayoutContext';

const createFormComponent = ({
  form_json_fields,
  sys_texts,
  crud_texts,
  routes,
  get_all_records,
  update_record,
  insert_record,
  get_dropdown_data,
  get_dropdown_data_value,
  get_dropdown_ds,
  get_dropdown_trec,
  get_dropdown_fields,
  get_cruce_records,
  download_file_resultado,
  download_file_resumen
}) => {
  const FormComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { updateData, getSelectedRecord, uploading, progress, updateProgress, updateUploading, downloading, progressDownload, updateProgressDownload, updateDownloading } = useConnectionContext();
    const [formData, setFormData] = useState({});
    const [enableKeyFields, setEnableKeyFields] = useState(location.state?.set_enable_key_fields || false);
    const [uploadDisable, setUploadDisable] = useState(false);
    const { updateFooterMessage, selectedDominio, updateBgColor } = useLayoutContext();
    const [dropdownData, setDropdownData] = useState({});
    const [dropdownDSA, setDropdownDSA] = useState([]);
    const [dropdownDSB, setDropdownDSB] = useState([]);
    const [dropdownTrecA, setDropdownTrecA] = useState([]);
    const [dropdownTrecB, setDropdownTrecB] = useState([]);
    const [formatFields, setFormatFields] = useState([]);
    const [formatFieldsA, setFormatFieldsA] = useState([]);
    const [formatFieldsB, setFormatFieldsB] = useState([]);
    const [searchDropdown, setSearchDropdown] = useState(() => {
      let initialState = {};
      form_json_fields.forEach((field) => {
        if (field.type === 'dropdown') {
          initialState = {
            ...initialState,
            [field.field]: "",
          };
        }
      });
      return initialState;
    });
    const [searchDropdownDSA, setSearchDropdownDSA] = useState('');
    const [searchDropdownDSB, setSearchDropdownDSB] = useState('');
    const [searchDropdownTrecA, setSearchDropdownTrecA] = useState('');
    const [searchDropdownTrecB, setSearchDropdownTrecB] = useState('');
    const [selectedDropdownItem, setSelectedDropdownItem] = useState(() => {
      let initialState = {};
      const selectedRecord = getSelectedRecord();
      if (location.state?.set_enable_key_fields && location.state?.idSelection) {
        // Ingreso de nuevo registro proveniente de una selección: cargamos el id del componente select
        setFormData(JSON.parse(location.state.idSelection));
      }
      else if (location.state?.set_enable_key_fields) {
        // Ingreso de nuevo registro: limpiamos el formulario
        setFormData({});
      }
      else if (selectedRecord && !location.state?.upload) {
        // Edición de registro: cargamos el componente para edición
        setFormData(selectedRecord);
      }

      form_json_fields.forEach((field) => {
        if (field.type === 'dropdown') {
          if (location.state?.cruce) {
            initialState = {
              ...initialState,
              ['fldA' + field.field]: "",
              ['fldB' + field.field]: "",
            };
          }
          else {
            initialState = {
              ...initialState,
              [field.field]: "",
            };
          }
        }
      });
      return initialState;
    });
    const [selectedDSAItem, setSelectedDSAItem] = useState('Seleccione Data Source A');
    const [selectedDSBItem, setSelectedDSBItem] = useState('Seleccione Data Source B');
    const [selectedTrecA, setSelectedTrecA] = useState('Tipo de Registro');
    const [selectedTrecB, setSelectedTrecB] = useState('Tipo de Registro');
    const [selectedFields, setSelectedFields] = useState([]);
    const [selectedFieldsA, setSelectedFieldsA] = useState([]);
    const [selectedFieldsB, setSelectedFieldsB] = useState([]);

    useEffect(() => {
      let initialState = {};
      if (location.state?.resumen) {
        crud_texts.form_titulo = crud_texts.form_titulo_resumen;
      }
      if (location.state?.detalle) {
        crud_texts.form_titulo = crud_texts.form_titulo_detalle;
      }
    }, [location.state]);

    useEffect(() => {
      let initialState = {};
      if (location.state?.set_enable_key_fields) {
        form_json_fields.forEach((field) => {
          if (field.type === 'dropdown') {
            initialState = {
              ...initialState,
              [field.field]: "",
            };
          }
        });
        setSelectedDropdownItem(initialState);
      }
    }, [location.state]);

    useEffect(() => {
      let initialState = {};
      const getDropdowSelected = async () => {
        if (!location.state?.set_enable_key_fields) {
          // form_json_fields.forEach(async (field) => {
          for (let i = 0; i < form_json_fields.length; i++) {
            if (form_json_fields[i].type === 'dropdown') {
              let data = [];
              let dataA = [];
              let dataB = [];
              if (location.state?.cruce) {
                dataA = await get_dropdown_data_value(form_json_fields[i].field, formData['fldA' + form_json_fields[i].field]);
                dataB = await get_dropdown_data_value(form_json_fields[i].field, formData['fldB' + form_json_fields[i].field]);
              }
              else {
                data = await get_dropdown_data_value(form_json_fields[i].field, formData[form_json_fields[i].field], selectedDominio, location.state?.idSelection);
              }
              // let keep = true;
              // let value = '';
              // for (let i = 0; i < data.length && keep; i++){
              //   if (data[i][field.field + "value"] === formData[field.field]) {
              //     value = data[i][field.field];
              //     keep = false;
              //   }
              // }
              let value = '';
              let valueA = '';
              let valueB = '';
              if (location.state?.cruce) {
                if (dataA.length > 0) {
                  valueA = dataA[0][form_json_fields[i].field];
                }
                if (dataB.length > 0) {
                  valueB = dataB[0][form_json_fields[i].field];
                }
              }
              else {
                if (data.length > 0) {
                  value = data[0][form_json_fields[i].field];
                }
              }
              // initialState[field.field] = formData[field.field];
              // initialState[field.field] = value;
              if (location.state?.cruce) {
                initialState = {
                  ...initialState,
                  ['fldA' + form_json_fields[i].field]: valueA,
                  ['fldB' + form_json_fields[i].field]: valueB
                }
              }
              else {
                initialState = { ...initialState, [form_json_fields[i].field]: value }
              }
            }
          }
          setSelectedDropdownItem(initialState);
        };
      }
      getDropdowSelected();

    }, [formData, location.state, selectedDominio]);

    useEffect(() => {
      if (location.state?.cruce) {
        const getDropdownDS = async () => {
          const data = await get_dropdown_ds({ 'dse_dom_id': selectedDominio });
          setDropdownDSA(data);
          setDropdownDSB(data);
        }
        getDropdownDS();
      }

    }, [location.state?.cruce, selectedDominio]);

    useEffect(() => {
      const getDropdownData = async () => {
        let temporaryData = {};
        for (let i in form_json_fields) {
          if (form_json_fields[i].type === 'dropdown') {
            const data = await get_dropdown_data(form_json_fields[i].field, selectedDominio, location.state?.idSelection);
            temporaryData = {
              ...temporaryData,
              [form_json_fields[i].field]: data,
            };
          }
        }
        setDropdownData(temporaryData);
      }

      getDropdownData();

    }, [selectedDominio, location.state?.idSelection]);

    useEffect(() => {
      const selectedRecord = getSelectedRecord();
      if (location.state?.set_enable_key_fields && location.state?.idSelection) {
        // Ingreso de nuevo registro proveniente de una selección: cargamos el id del componente select
        setFormData(JSON.parse(location.state.idSelection));
        setEnableKeyFields(true);
      }
      else if (location.state?.set_enable_key_fields) {
        // Ingreso de nuevo registro: limpiamos el formulario

        let data = {};
        form_json_fields.forEach((field) => {
          if (field?.dom) {
            data = { [field.field]: selectedDominio }
          };
        });

        setFormData(data);
        setEnableKeyFields(true);
      }
      else if (selectedRecord && !location.state?.upload) {
        // Edición de registro: cargamos el componente para edición
        setFormData(selectedRecord);
      }
    }, [location.state, getSelectedRecord, selectedDominio]);


    useEffect(() => {
      if (location.state?.cruce && location.state?.edit) {
        const getCruceRecords = async () => {
          const selectedRecord = getSelectedRecord();
          const dataDSA = await get_cruce_records({
            'rule_dom_id': selectedRecord.rule_dom_id,
            'rule_cruce_id': selectedRecord.rule_cruce_id,
            'rule_side': 'A'
          });
          let resultArrayFormatFields = [];
          let resultArraySelectedFields = [];
          setFormatFieldsA([]);
          setFormatFieldsB([]);
          setFormatFields([]);
          let formDataA = {};
          let formDataB = {};
          if (dataDSA.length > 0) {
            form_json_fields.forEach((item) => {
              formDataA = { ...formDataA, ['fldA' + item.field]: dataDSA[0][item.field] }
            });
            setSelectedDSAItem(dataDSA[0].rule_dse_id);
            setSelectedTrecA(dataDSA[0].rule_rectype_id);
            const data = await get_dropdown_trec({ 'dse_dom_id': selectedRecord.rule_dom_id, 'dse_id': dataDSA[0].rule_dse_id });
            setDropdownTrecA(data);
            const dataFieldsA = await get_dropdown_fields({
              'dse_dom_id': selectedRecord.rule_dom_id,
              'dse_id': dataDSA[0].rule_dse_id,
              'dse_rectype_id': dataDSA[0].rule_rectype_id
            });
            setFormatFieldsA(dataFieldsA);
            resultArrayFormatFields = [];
            for (let i = 0; i < dataFieldsA.length; i++) {
              resultArrayFormatFields[i] = [];
              resultArrayFormatFields[i][0] = dataFieldsA[i];
            }
            setFormatFields(resultArrayFormatFields);
            setSelectedFieldsA([]);
            const filteredArray = dataFieldsA.filter((field) =>
              dataDSA.some((item) =>
                item?.rule_key_field_name === field.dse_field_name ||
                item?.rule_field_name === field.dse_field_name
              )
            )
            const sortedArray = filteredArray.map((field) =>
              ({ 'dse_field_number': 'chkA' + field.dse_field_number, 'dse_field_name': field.dse_field_name })
            )
            const selectedArray = [];
            sortedArray.forEach((field) => {
              dataDSA.forEach((item) => {
                if (item?.rule_key_field_name === field.dse_field_name)
                  selectedArray[item.rule_key_order - 1] = field;
                if (item?.rule_field_name === field.dse_field_name)
                  selectedArray[item.rule_field_order - 1] = field;
              })
            })
            setSelectedFieldsA(selectedArray);
            resultArraySelectedFields = [];
            for (let i = 0; i < selectedArray.length; i++) {
              resultArraySelectedFields[i] = [];
              resultArraySelectedFields[i][0] = selectedArray[i];
            }
            setSelectedFields(resultArraySelectedFields);
          }
          else {
            formDataA = { ...formDataA, fldArule_factor: "1.00" }
          }
          const dataDSB = await get_cruce_records({
            'rule_dom_id': selectedRecord.rule_dom_id,
            'rule_cruce_id': selectedRecord.rule_cruce_id,
            'rule_side': 'B'
          });
          if (dataDSB.length > 0) {
            form_json_fields.forEach((item) => {
              formDataB = { ...formDataB, ['fldB' + item.field]: dataDSB[0][item.field] }
            });
            setSelectedDSBItem(dataDSB[0].rule_dse_id);
            setSelectedTrecB(dataDSB[0].rule_rectype_id);
            const data = await get_dropdown_trec({ 'dse_dom_id': selectedRecord.rule_dom_id, 'dse_id': dataDSB[0].rule_dse_id });
            setDropdownTrecB(data);
            const dataFieldsB = await get_dropdown_fields({
              'dse_dom_id': selectedRecord.rule_dom_id,
              'dse_id': dataDSB[0].rule_dse_id,
              'dse_rectype_id': dataDSB[0].rule_rectype_id
            });
            setFormatFieldsB(dataFieldsB);
            for (let i = 0; i < dataFieldsB.length; i++) {
              if (!Array.isArray(resultArrayFormatFields[i]))
                resultArrayFormatFields[i] = [];
              resultArrayFormatFields[i][1] = dataFieldsB[i];
            }
            setFormatFields(resultArrayFormatFields);
            setSelectedFieldsB([]);
            const filteredArray = dataFieldsB.filter((field) =>
              dataDSB.some((item) =>
                item?.rule_key_field_name === field.dse_field_name ||
                item?.rule_field_name === field.dse_field_name
              )
            )
            const sortedArray = filteredArray.map((field) =>
              ({ 'dse_field_number': 'chkB' + field.dse_field_number, 'dse_field_name': field.dse_field_name })
            )
            const selectedArray = [];
            sortedArray.forEach((field) => {
              dataDSB.forEach((item) => {
                if (item?.rule_key_field_name === field.dse_field_name)
                  selectedArray[item.rule_key_order - 1] = field;
                if (item?.rule_field_name === field.dse_field_name)
                  selectedArray[item.rule_field_order - 1] = field;
              })
            })
            setSelectedFieldsB(selectedArray);
            for (let i = 0; i < selectedArray.length; i++) {
              if (!Array.isArray(resultArraySelectedFields[i]))
                resultArraySelectedFields[i] = [];
              resultArraySelectedFields[i][1] = selectedArray[i];
            }
            setSelectedFields(resultArraySelectedFields);
          }
          else {
            formDataB = { ...formDataB, fldBrule_factor: "1.00" }
          }
          setFormData({ ...formDataA, ...formDataB });
        }
        if (!location.state?.set_enable_key_fields) {
          getCruceRecords();
        }
      }
    // }, [location.state?.set_enable_key_fields, location.state?.cruce, location.state?.edit, getSelectedRecord]);
    }, []);

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    };

    const handleOnInput = (e) => {
      const regex = new RegExp(/[a-z0-9+=.()_-\s]/, "i");

      if (!regex.test(e.key))
        e.preventDefault();
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      if (!uploadDisable) {
        if (location.state?.edit) { //Actualización del registro
          if (location.state?.cruce) {
            const selectedRecord = getSelectedRecord();
            let fieldsA = [];
            selectedFieldsA.forEach((item, index) => {
              if (location.state?.cruceCampos)
                fieldsA[index] = { 'rule_field_order': index + 1, 'rule_field_name': item.dse_field_name };
              else
                fieldsA[index] = { 'rule_key_order': index + 1, 'rule_key_field_name': item.dse_field_name };
            });
            let fieldsB = [];
            selectedFieldsB.forEach((item, index) => {
              if (location.state?.cruceCampos)
                fieldsB[index] = { 'rule_field_order': index + 1, 'rule_field_name': item.dse_field_name };
              else
                fieldsB[index] = { 'rule_key_order': index + 1, 'rule_key_field_name': item.dse_field_name };
            });

            let data = {};
            if (location.state?.cruceCampos) {
              data = {
                'rule_dom_id': selectedRecord.rule_dom_id,
                'rule_cruce_id': selectedRecord.rule_cruce_id,
                'rule_dse_idA': selectedDSAItem,
                'rule_dse_idB': selectedDSBItem,
                'rule_rectype_idA': selectedTrecA,
                'rule_rectype_idB': selectedTrecB,
                'fieldsA': fieldsA,
                'fieldsB': fieldsB,
              }
              form_json_fields.forEach((item) => {
                if (formData['fldA' + item.field])
                  data = { ...data, [item.field + 'A']: formData['fldA' + item.field] };
                if (formData['fldB' + item.field])
                  data = { ...data, [item.field + 'B']: formData['fldB' + item.field] };
              });
            }
            else {
              data = {
                'rule_dom_id': selectedRecord.rule_dom_id,
                'rule_cruce_id': selectedRecord.rule_cruce_id,
                'rule_dse_idA': selectedDSAItem,
                'rule_dse_idB': selectedDSBItem,
                'rule_table_nameA': selectedDSAItem + '_' + selectedTrecA,
                'rule_table_nameB': selectedDSBItem + '_' + selectedTrecB,
                'rule_rectype_idA': selectedTrecA,
                'rule_rectype_idB': selectedTrecB,
                'fieldsA': fieldsA,
                'fieldsB': fieldsB
              }
            }
            await update_record(data);
          }
          else {
            await update_record(formData);
          }
          // const updatedData = await get_all_records(location.state?.idSelection);
          let updatedData = [];
          if (location.state?.idSelection) {
            updatedData = await get_all_records(location.state.idSelection);
            //OBTIENE TODOS LOS REGISTROS PARA UPLOAD DE DS O PARA INICIO O RESUMEN DE PROCESO
          } else if (!location.state?.isBrowseNotDomReq) {
            if (selectedDominio) {
              updatedData = await get_all_records({ 'dom_id': selectedDominio });
            }
            //OBTIENE TODOS LOS REGISTROS SIN DEPENDENCIA DEL DOMINIO
          } else {
            updatedData = await get_all_records();
          }
          updateData(updatedData);
          location.state?.idSelection ?
            navigate(routes.list, { state: { currentPage: location.state.currentPage, searchTerm: location.state.searchTerm, idSelection: location.state.idSelection } })
            :
            navigate(routes.list, { state: { currentPage: location.state.currentPage, searchTerm: location.state.searchTerm } });
        }
        else if (location.state?.upload && !location.state?.uploadDef) { //Carga de Data Source
          if (!formData?.dse_file) {
            updateFooterMessage('Debe seleccionar un archivo para cargar');
            updateBgColor('alert');
          }
          else {
            updateFooterMessage('Zona de mensajes o alertas');
            updateBgColor('bland');
            const selectedRecord = getSelectedRecord();
            const fileName = {
              dse_dom_id: selectedRecord.dse_dom_id,
              dse_wks_id: selectedRecord.dse_wks_id,
              dse_id: selectedRecord.dse_id,
              // dse_length: selectedRecord.dse_max_len,
              now: + Date.now(),
            }
            const fileNameJson = JSON.stringify(fileName);
            const fileInput = document.getElementById('dse_file');
            const file = fileInput.files[0];
            const data = new FormData();
            data.append('name', fileNameJson);
            data.append('file', file);
            setUploadDisable(true);
            await insert_record(data, updateUploading, updateProgress);
            await new Promise(resolve => setTimeout(resolve, 1000));
            setUploadDisable(false);
            setFormData({});
            handleCancel(e);
          }
        }
        else if (location.state?.upload && location.state?.uploadDef) { //Carga de Definición de Data Source
          if (!formData?.dse_file) {
            updateFooterMessage('Debe seleccionar un archivo para cargar');
            updateBgColor('alert');
          }
          else {
            updateFooterMessage('Zona de mensajes o alertas');
            updateBgColor('bland');
            const selectedRecord = getSelectedRecord();
            const dom_id = selectedRecord.dom_id;
            const now = Date.now();
            const name = dom_id + now;
            const fileInput = document.getElementById('dse_file');
            const file = fileInput.files[0];
            const data = new FormData();
            data.append('name', name);
            data.append('dom_id', dom_id);
            data.append('file', file);
            setUploadDisable(true);
            await insert_record(data, updateUploading, updateProgress);
            setUploadDisable(false);
            setFormData({});
          }
        }
        else if (location.state?.resumen) { //Descarga de resumen de Step
          const selectedRecord = getSelectedRecord();
          setUploadDisable(true);
          await download_file_resumen({
            'process_id': selectedRecord.process_id,
            'process_dom_id': selectedRecord.process_dom_id,
            'process_exec_spacework': selectedRecord.process_exec_spacework,
            'step_id': selectedRecord.step_id
          }, updateDownloading, updateProgressDownload);
          setUploadDisable(false);
        }
        else if (location.state?.detalle) { //Descarga de resultado de Step
          const selectedRecord = getSelectedRecord();
          setUploadDisable(true);
          await download_file_resultado({
            'process_id': selectedRecord.process_id,
            'process_dom_id': selectedRecord.process_dom_id,
            'process_exec_spacework': selectedRecord.process_exec_spacework,
            'step_id': selectedRecord.step_id
          }, updateDownloading, updateProgressDownload);
          setUploadDisable(false);
        }
        else { //Ingreso del registro
          await insert_record(formData);
          // const updatedData = await get_all_records(location.state?.idSelection);
          let updatedData = [];
          if (location.state?.idSelection) {
            updatedData = await get_all_records(location.state.idSelection);
            //OBTIENE TODOS LOS REGISTROS PARA UPLOAD DE DS O PARA INICIO O RESUMEN DE PROCESO
          } else if (!location.state?.isBrowseNotDomReq) {
            if (selectedDominio) {
              updatedData = await get_all_records({ 'dom_id': selectedDominio });
            }
            //OBTIENE TODOS LOS REGISTROS SIN DEPENDENCIA DEL DOMINIO
          } else {
            updatedData = await get_all_records();
          }
          updateData(updatedData);
          location.state?.idSelection ?
            navigate(routes.list, { state: { currentPage: location.state.currentPage, searchTerm: location.state.searchTerm, idSelection: location.state.idSelection } })
            :
            navigate(routes.list, { state: { currentPage: location.state.currentPage, searchTerm: location.state.searchTerm } });
        }
      }
    };

    const handleCancel = (e) => {
      e.preventDefault();
      if (!uploadDisable) {
        updateUploading(false);
        updateDownloading(false);
        location.state?.idSelection ?
          navigate(routes.list, { state: { currentPage: location.state.currentPage, searchTerm: location.state.searchTerm, idSelection: location.state.idSelection } })
          :
          location.state?.processId ?
            navigate(routes.list, { state: { currentPage: location.state.currentPage, searchTerm: location.state.searchTerm, processId: location.state.processId } })
            :
            navigate(routes.list, { state: { currentPage: location.state.currentPage, searchTerm: location.state.searchTerm } });
      }
    };

    const handleSelectDropdown = (e, selectedItem, inputId, value) => {
      e.preventDefault();
      setSelectedDropdownItem({
        ...selectedDropdownItem,
        // [inputId]: selectedItem,
        [inputId]: selectedItem,
      });

      setFormData(prevState => ({
        ...prevState,
        // [inputId]: selectedItem
        [inputId]: value
      }));
    };

    const handleSelectDropdownDSA = async (e, selectedItem) => {
      e.preventDefault();
      setSelectedDSAItem(selectedItem);
      const data = await get_dropdown_trec({ 'dse_dom_id': selectedDominio, 'dse_id': selectedItem });
      setDropdownTrecA(data);
      setSelectedTrecA('Tipo de Registro');
      setFormatFieldsA([]);
      let resultArray = [];
      for (let i = 0; i < formatFieldsB.length; i++) {
        resultArray[i] = [];
        resultArray[i][1] = formatFieldsB[i];
      }
      setFormatFields(resultArray);

      let sortedArray = [];
      setSelectedFieldsA(sortedArray);
      resultArray = [];
      for (let i = 0; i < selectedFieldsB.length; i++) {
        resultArray[i] = [];
        resultArray[i][1] = selectedFieldsB[i];
      }
      setSelectedFields(resultArray);
    };

    const handleSelectDropdownDSB = async (e, selectedItem) => {
      e.preventDefault();
      setSelectedDSBItem(selectedItem);
      const data = await get_dropdown_trec({ 'dse_dom_id': selectedDominio, 'dse_id': selectedItem });
      setDropdownTrecB(data);
      setSelectedTrecB('Tipo de Registro');
      setFormatFieldsB([]);
      let resultArray = [];
      for (let i = 0; i < formatFieldsA.length; i++) {
        resultArray[i] = [];
        resultArray[i][0] = formatFieldsA[i];
      }
      setFormatFields(resultArray);

      let sortedArray = [];
      setSelectedFieldsB(sortedArray);
      resultArray = [];
      for (let i = 0; i < selectedFieldsA.length; i++) {
        resultArray[i] = [];
        resultArray[i][0] = selectedFieldsA[i];
      }
      setSelectedFields(resultArray);
    };

    const handleSelectDropdownTrecA = async (e, selectedItem) => {
      e.preventDefault();
      setSelectedTrecA(selectedItem);
      setFormatFieldsA([]);
      const data = await get_dropdown_fields({ 'dse_dom_id': selectedDominio, 'dse_id': selectedDSAItem, 'dse_rectype_id': selectedItem });
      setFormatFieldsA(data);
      let resultArray = [];
      for (let i = 0; i < data.length; i++) {
        resultArray[i] = [];
        resultArray[i][0] = data[i];
      }
      for (let i = 0; i < formatFieldsB.length; i++) {
        if (!Array.isArray(resultArray[i]))
          resultArray[i] = [];
        resultArray[i][1] = formatFieldsB[i];
      }
      setFormatFields(resultArray);

      const sortedArray = [];
      setSelectedFieldsA(sortedArray);
      resultArray = [];
      for (let i = 0; i < selectedFieldsB.length; i++) {
        resultArray[i] = [];
        resultArray[i][1] = selectedFieldsB[i];
      }
      setSelectedFields(resultArray);
    };

    const handleSelectDropdownTrecB = async (e, selectedItem) => {
      e.preventDefault();
      setSelectedTrecB(selectedItem);
      setFormatFieldsB([]);
      const data = await get_dropdown_fields({ 'dse_dom_id': selectedDominio, 'dse_id': selectedDSBItem, 'dse_rectype_id': selectedItem });
      setFormatFieldsB(data);
      let resultArray = [];
      for (let i = 0; i < data.length; i++) {
        resultArray[i] = [];
        resultArray[i][1] = data[i];
      }
      for (let i = 0; i < formatFieldsA.length; i++) {
        if (!Array.isArray(resultArray[i]))
          resultArray[i] = [];
        resultArray[i][0] = formatFieldsA[i];
      }
      setFormatFields(resultArray);

      const sortedArray = [];
      setSelectedFieldsB(sortedArray);
      resultArray = [];
      for (let i = 0; i < selectedFieldsA.length; i++) {
        resultArray[i] = [];
        resultArray[i][0] = selectedFieldsA[i];
      }
      setSelectedFields(resultArray);
    };

    // Manejar la búsqueda de registros en los dropdown
    const handleSearch = (e, dropdown) => {
      setSearchDropdown({
        ...searchDropdown,
        [dropdown]: e.target.value
      });
    };

    // Manejar la búsqueda de registros en el dropdown del Data Source A
    const handleSearchDSA = (e) => {
      setSearchDropdownDSA(e.target.value);
    };

    // Manejar la búsqueda de registros en el dropdown del Data Source B
    const handleSearchDSB = (e) => {
      setSearchDropdownDSB(e.target.value);
    };

    // Manejar la búsqueda de registros en el dropdown del Tipo de Registro A
    const handleSearchTrecA = (e) => {
      setSearchDropdownTrecA(e.target.value);
    };

    // Manejar la búsqueda de registros en el dropdown del Tipo de Registro B
    const handleSearchTrecB = (e) => {
      setSearchDropdownTrecB(e.target.value);
    };

    // Filtrar los datos de los dropdown según el término de búsqueda
    const filteredData = (field) => {
      let result = {};

      if (dropdownData[field]) {
        result[field] = dropdownData[field].filter(
          (item) => {
            if (!searchDropdown[field])
              return true;
            return item[field].toString().toLowerCase().includes(searchDropdown[field].toLowerCase())
          }
        )
      }
      return result;
    };

    // Filtrar los datos del Dropdown Data Source A según el término de búsqueda
    const filteredDataDSA = dropdownDSA.filter(
      (item) =>
        item.dse_id.toString().toLowerCase().includes(searchDropdownDSA.toLowerCase())
    );

    // Filtrar los datos del Dropdown Data Source B según el término de búsqueda
    const filteredDataDSB = dropdownDSB.filter(
      (item) =>
        item.dse_id.toString().toLowerCase().includes(searchDropdownDSB.toLowerCase())
    );

    // Filtrar los datos del Dropdown Data Source B según el término de búsqueda
    const filteredDataTrecA = dropdownTrecA.filter(
      (item) =>
        item.dse_id.toString().toLowerCase().includes(searchDropdownTrecA.toLowerCase())
    );

    // Filtrar los datos del Dropdown Data Source B según el término de búsqueda
    const filteredDataTrecB = dropdownTrecB.filter(
      (item) =>
        item.dse_id.toString().toLowerCase().includes(searchDropdownTrecB.toLowerCase())
    );

    const handleCheckboxChangeA = (e) => {
      const checkedId = e.target.id;
      const checkedName = e.target.name;

      if (e.target.checked) {
        let sortedArray = [...selectedFieldsA, { 'dse_field_number': checkedId, 'dse_field_name': checkedName }];
        setSelectedFieldsA([...sortedArray]);

        const resultArray = [];
        for (let i = 0; i < sortedArray.length; i++) {
          resultArray[i] = [];
          resultArray[i][0] = sortedArray[i];
        }
        for (let i = 0; i < selectedFieldsB.length; i++) {
          if (!Array.isArray(resultArray[i]))
            resultArray[i] = [];
          resultArray[i][1] = selectedFieldsB[i];
        }
        setSelectedFields(resultArray);
      } else {
        let sortedArray = selectedFieldsA.filter(id => id.dse_field_number !== checkedId);
        setSelectedFieldsA(sortedArray);

        const resultArray = [];
        for (let i = 0; i < sortedArray.length; i++) {
          resultArray[i] = [];
          resultArray[i][0] = sortedArray[i];
        }
        for (let i = 0; i < selectedFieldsB.length; i++) {
          if (!Array.isArray(resultArray[i]))
            resultArray[i] = [];
          resultArray[i][1] = selectedFieldsB[i];
        }
        setSelectedFields(resultArray);
      }
    }

    const handleCheckboxChangeB = (e) => {
      const checkedId = e.target.id;
      const checkedName = e.target.name;

      if (e.target.checked) {
        let sortedArray = [...selectedFieldsB, { 'dse_field_number': checkedId, 'dse_field_name': checkedName }];
        setSelectedFieldsB([...sortedArray]);

        const resultArray = [];
        for (let i = 0; i < sortedArray.length; i++) {
          resultArray[i] = [];
          resultArray[i][1] = sortedArray[i];
        }
        for (let i = 0; i < selectedFieldsA.length; i++) {
          if (!Array.isArray(resultArray[i]))
            resultArray[i] = [];
          resultArray[i][0] = selectedFieldsA[i];
        }
        setSelectedFields(resultArray);
      } else {
        let sortedArray = selectedFieldsB.filter(id => id.dse_field_number !== checkedId);
        setSelectedFieldsB(sortedArray);

        const resultArray = [];
        for (let i = 0; i < sortedArray.length; i++) {
          resultArray[i] = [];
          resultArray[i][1] = sortedArray[i];
        }
        for (let i = 0; i < selectedFieldsA.length; i++) {
          if (!Array.isArray(resultArray[i]))
            resultArray[i] = [];
          resultArray[i][0] = selectedFieldsA[i];
        }
        setSelectedFields(resultArray);
      }
    }

    return (
      location.state?.cruce ? //FORM CRUCE
        <>
          <div className="form_modal-overlay" onClick={handleCancel}></div>
          <div className="form_modal_cruce">
            <div className="form_card-header">
              <h4 className="form_title">{crud_texts.form_titulo}</h4>
              <button className="form_btn-close" id="btn-close" onClick={handleCancel}>X</button>
            </div>
            <div className="form_card-body">
              <form onSubmit={handleSubmit}>
                <div className="container-fluid">
                  <div className="row mb-3">
                    {/* <div className="col-6 d-flex justify-content-center align-items-center"> */}
                    <div className="col-6 d-flex">
                      <div className="dropdown">
                        <button
                          className="btn btn-outline-primary dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                        >
                          {selectedDSAItem}
                        </button>
                        <div className="dropdown-menu dropdown-menu-end">
                          <input
                            type="text"
                            className="dropdown-item"
                            placeholder="Buscar..."
                            onChange={(e) => handleSearchDSA(e)} value={searchDropdownDSA}
                          />
                          <a
                            className="dropdown-item"
                            href="/#"
                            onClick={(e) => handleSelectDropdownDSA(e, 'Seleccione Data Source A')}
                          >
                            (Limpiar Selección)
                          </a>
                          {filteredDataDSA?.map((item) =>
                            <a
                              className="dropdown-item"
                              key={"dsa" + item.dse_id}
                              href="/#"
                              onClick={(e) => handleSelectDropdownDSA(e, item.dse_id)}
                            >
                              {item.dse_id}
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-6 d-flex justify-content-center align-items-center"> */}
                    <div className="col-6 d-flex">
                      <div className="dropdown">
                        <button
                          className="btn btn-outline-primary dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                        >
                          {selectedDSBItem}
                        </button>
                        <div className="dropdown-menu dropdown-menu-end">
                          <input
                            type="text"
                            className="dropdown-item"
                            placeholder="Buscar..."
                            onChange={(e) => handleSearchDSB(e)} value={searchDropdownDSB}
                          />
                          <a
                            className="dropdown-item"
                            href="/#"
                            onClick={(e) => handleSelectDropdownDSB(e, 'Seleccione Data Source B')}
                          >
                            (Limpiar Selección)
                          </a>
                          {filteredDataDSB?.map((item) =>
                            <a
                              className="dropdown-item"
                              key={"dsb" + item.dse_id}
                              href="/#"
                              onClick={(e) => handleSelectDropdownDSB(e, item.dse_id)}
                            >
                              {item.dse_id}
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    {/* <div className="col-6 d-flex justify-content-center align-items-center"> */}
                    <div className="col-6 d-flex">
                      <div className="dropdown">
                        <button
                          className="btn btn-outline-primary dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                        >
                          {selectedTrecA}
                        </button>
                        <div className="dropdown-menu dropdown-menu-end">
                          <input
                            type="text"
                            className="dropdown-item"
                            placeholder="Buscar..."
                            onChange={(e) => handleSearchTrecA(e)} value={searchDropdownTrecA}
                          />
                          <a
                            className="dropdown-item"
                            href="/#"
                            onClick={(e) => handleSelectDropdownTrecA(e, 'Tipo de Registro')}
                          >
                            (Limpiar Selección)
                          </a>
                          {filteredDataTrecA?.map((item) =>
                            <a
                              className="dropdown-item"
                              key={"treca" + item.dse_rectype_id}
                              href="/#"
                              onClick={(e) => handleSelectDropdownTrecA(e, item.dse_rectype_id)}
                            >
                              {item.dse_rectype_id}
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-6 d-flex justify-content-center align-items-center"> */}
                    <div className="col-6 d-flex">
                      <div className="dropdown">
                        <button
                          className="btn btn-outline-primary dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                        >
                          {selectedTrecB}
                        </button>
                        <div className="dropdown-menu dropdown-menu-end">
                          <input
                            type="text"
                            className="dropdown-item"
                            placeholder="Buscar..."
                            onChange={(e) => handleSearchTrecB(e)} value={searchDropdownTrecB}
                          />
                          <a
                            className="dropdown-item"
                            href="/#"
                            onClick={(e) => handleSelectDropdownTrecB(e, 'Tipo de Registro')}
                          >
                            (Limpiar Selección)
                          </a>
                          {filteredDataTrecB?.map((item) =>
                            <a
                              className="dropdown-item"
                              key={"trecb" + item.dse_rectype_id}
                              href="/#"
                              onClick={(e) => handleSelectDropdownTrecB(e, item.dse_rectype_id)}
                            >
                              {item.dse_rectype_id}
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {formatFields.map((item) => {
                    return (
                      <div className="row mb-3" key={
                        formatFieldsA.length > formatFieldsB.length ?
                          'chk' + item[0]?.dse_field_number :
                          'chk' + item[1]?.dse_field_number
                      }>
                        {/* <div className="col-6 d-flex justify-content-center align-items-center"> */}
                        <div className="col-6 d-flex">
                          <div>
                            {item[0] ?
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={'chkA' + item[0]?.dse_field_number}
                                  name={item[0].dse_field_name}
                                  value={item[0]?.dse_field_number}
                                  checked={selectedFieldsA.some((field) => field?.dse_field_number === 'chkA' + item[0]?.dse_field_number)}
                                  onChange={(e) => handleCheckboxChangeA(e)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={'chkA' + item[0]?.dse_field_number}
                                >
                                  {item[0].dse_field_name}</label>
                              </div>
                              :
                              null}
                          </div>
                        </div>
                        {/* <div className="col-6 d-flex justify-content-center align-items-center"> */}
                        <div className="col-6 d-flex">
                          <div>
                            {item[1] ?
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={'chkB' + item[1]?.dse_field_number}
                                  name={item[1]?.dse_field_name}
                                  value={item[1]?.dse_field_number}
                                  checked={selectedFieldsB.some((field) => field?.dse_field_number === 'chkB' + item[1]?.dse_field_number)}
                                  onChange={(e) => handleCheckboxChangeB(e)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={'chkB' + item[1]?.dse_field_number}
                                >
                                  {item[1].dse_field_name}
                                </label>
                              </div>
                              :
                              null}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                  <div className="row mb-3">
                    <div className="col-6 d-flex">
                      {
                        selectedFieldsA.length > 0 ?
                          <b>Campos seleccionados</b>
                          :
                          null
                      }
                    </div>
                    <div className="col-6 d-flex">
                      {
                        selectedFieldsB.length > 0 ?
                          <b>Campos seleccionados</b>
                          :
                          null
                      }
                    </div>
                  </div>
                  {selectedFields.map((item, index) => {
                    return (
                      <div className="row mb-3" key={
                        selectedFieldsA.length > selectedFieldsB.length ?
                          'selchk' + item[0]?.dse_field_number :
                          'selchk' + item[1]?.dse_field_number}>
                        <div className="col-6 d-flex">
                          <div>
                            {item[0] ?
                              <div>
                                {index + 1 + '.- ' + item[0]?.dse_field_name}
                              </div>
                              :
                              null}
                          </div>
                        </div>
                        <div className="col-6 d-flex">
                          <div>
                            {item[1] ?
                              <div>
                                {index + 1 + '.- ' + item[1]?.dse_field_name}
                              </div>
                              :
                              null}
                          </div>
                        </div>
                      </div>
                    )
                  })}



                  {selectedFields.map((item, index) => {
                    return (
                      <div className="row mb-3" key={
                        selectedFieldsA.length > selectedFieldsB.length ?
                          'selchk' + item[0]?.dse_field_number :
                          'selchk' + item[1]?.dse_field_number}>
                        <div className="col-6 d-flex">
                          <div>
                            {form_json_fields.map((field) => {
                              if (field.type === 'dropdown') { //TIPO DE CAMPO DROPDOWN
                                return (
                                  <div className="form_group" key={'fldA' + field.field}>
                                    <label htmlFor={'fldA' + field.field}>{field.title}</label>
                                    <input
                                      type="text"
                                      id={'fldA' + field.field}
                                      name={'fldA' + field.field}
                                      value={selectedDropdownItem?.['fldA' + field.field]}
                                      disabled={true}
                                      readOnly={true}
                                      className="form-control read-only-input"
                                    />
                                    <div className="input-group-append">
                                      <button
                                        id={"btnfldA" + field.field}
                                        className="btn btn-outline-primary dropdown-toggle"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        disabled={(field.key && !enableKeyFields) || (enableKeyFields && location.state?.idSelection && field.field in JSON.parse(location.state?.idSelection)) || uploadDisable || field.blocked}
                                      >
                                      </button>
                                      <div className="dropdown-menu dropdown-menu-end">
                                        <input
                                          type="text"
                                          className="dropdown-item"
                                          placeholder="Buscar..."
                                          onChange={(e) => handleSearch(e, field.field)} value={searchDropdown[field.field]}
                                        />
                                        <a
                                          className="dropdown-item"
                                          // key={"dd" + field.field}
                                          href="/#"
                                          onClick={(e) => handleSelectDropdown(e, '', 'fldA' + field.field, '')}
                                        >
                                          (Limpiar Selección)
                                        </a>
                                        {filteredData(field.field)[field.field]?.map((item) =>
                                          <a
                                            className="dropdown-item"
                                            key={"ddfldA" + field.field + item[field.field]}
                                            href="/#"
                                            value={item[field.field + "value"]}
                                            onClick={(e) => handleSelectDropdown(e, item[field.field], 'fldA' + field.field, item[field.field + "value"])}
                                          >
                                            {item[field.field]}
                                          </a>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )
                              }
                              else { //OTROS TIPOS DE CAMPO DISTINTO DE DROPDOWN
                                return (
                                  <div className="form_group" key={'fldA' + field.field}>
                                    <label htmlFor={'fldA' + field.field}>{field.title}</label>
                                    <input
                                      type={field.type}
                                      id={'fldA' + field.field}
                                      name={'fldA' + field.field}
                                      value={formData['fldA' + field.field] || ''}
                                      onChange={handleChange}
                                      onKeyDown={handleOnInput}
                                      disabled={(field.key && !enableKeyFields) || (enableKeyFields && location.state?.idSelection && field.field in JSON.parse(location.state?.idSelection)) || uploadDisable || field.blocked}
                                      readOnly={(field.key && !enableKeyFields) || (enableKeyFields && location.state?.idSelection && field.field in JSON.parse(location.state?.idSelection)) || field.blocked} // Activo o no basado en enableKeyFields
                                      className={`form-control ${(field.key && !enableKeyFields) || (enableKeyFields && location.state?.idSelection && field.field in JSON.parse(location.state?.idSelection)) || field.blocked ? 'read-only-input' : ''}`}
                                    />
                                  </div>
                                )
                              }
                            })}
                          </div>
                        </div>
                        <div className="col-6 d-flex">
                          <div>
                            {form_json_fields.map((field) => {
                              if (field.type === 'dropdown') { //TIPO DE CAMPO DROPDOWN
                                return (
                                  <div className="form_group" key={'fldB' + field.field}>
                                    <label htmlFor={'fldB' + field.field}>{field.title}</label>
                                    <input
                                      type="text"
                                      id={'fldB' + field.field}
                                      name={'fldB' + field.field}
                                      value={selectedDropdownItem?.['fldB' + field.field]}
                                      disabled={true}
                                      readOnly={true}
                                      className="form-control read-only-input"
                                    />
                                    <div className="input-group-append">
                                      <button
                                        id={"btnfldB" + field.field}
                                        className="btn btn-outline-primary dropdown-toggle"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        disabled={(field.key && !enableKeyFields) || (enableKeyFields && location.state?.idSelection && field.field in JSON.parse(location.state?.idSelection)) || uploadDisable || field.blocked}
                                      >
                                      </button>
                                      <div className="dropdown-menu dropdown-menu-end">
                                        <input
                                          type="text"
                                          className="dropdown-item"
                                          placeholder="Buscar..."
                                          onChange={(e) => handleSearch(e, field.field)} value={searchDropdown[field.field]}
                                        />
                                        <a
                                          className="dropdown-item"
                                          // key={"dd" + field.field}
                                          href="/#"
                                          onClick={(e) => handleSelectDropdown(e, '', 'fldB' + field.field, '')}
                                        >
                                          (Limpiar Selección)
                                        </a>
                                        {filteredData(field.field)[field.field]?.map((item) =>
                                          <a
                                            className="dropdown-item"
                                            key={"ddfldB" + field.field + item[field.field]}
                                            href="/#"
                                            value={item[field.field + "value"]}
                                            onClick={(e) => handleSelectDropdown(e, item[field.field], 'fldB' + field.field, item[field.field + "value"])}
                                          >
                                            {item[field.field]}
                                          </a>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )
                              }
                              else { //OTROS TIPOS DE CAMPO DISTINTO DE DROPDOWN
                                return (
                                  <div className="form_group" key={'fldB' + field.field}>
                                    <label htmlFor={'fldB' + field.field}>{field.title}</label>
                                    <input
                                      type={field.type}
                                      id={'fldB' + field.field}
                                      name={'fldB' + field.field}
                                      value={formData['fldB' + field.field] || ''}
                                      onChange={handleChange}
                                      onKeyDown={handleOnInput}
                                      disabled={(field.key && !enableKeyFields) || (enableKeyFields && location.state?.idSelection && field.field in JSON.parse(location.state?.idSelection)) || uploadDisable || field.blocked}
                                      readOnly={(field.key && !enableKeyFields) || (enableKeyFields && location.state?.idSelection && field.field in JSON.parse(location.state?.idSelection)) || field.blocked} // Activo o no basado en enableKeyFields
                                      className={`form-control ${(field.key && !enableKeyFields) || (enableKeyFields && location.state?.idSelection && field.field in JSON.parse(location.state?.idSelection)) || field.blocked ? 'read-only-input' : ''}`}
                                    />
                                  </div>
                                )
                              }
                            })}
                          </div>
                        </div>
                      </div>
                    )
                  })}


                  <button type="submit" id="btn-submit" className="form_btn form_btn-primary">
                    {sys_texts.form_guardar}
                  </button>
                </div>
              </form>
              {uploading && (
                <div className="mt-3">
                  <ProgressBar now={progress} label={`${progress}%`} />
                </div>
              )}
              {downloading && (
                <div className="mt-3">
                  <ProgressBar now={progressDownload} label={`${progressDownload}%`} />
                </div>
              )}
            </div >
          </div >
        </>
        : //FORM REGULAR
        <>
          <div className="form_modal-overlay" onClick={handleCancel}></div>
          <div className="form_modal">
            <div className="form_card-header">
              <h4 className="form_title">{crud_texts.form_titulo}</h4>
              <button className="form_btn-close" id="btn-close" onClick={handleCancel}>X</button>
            </div>
            <div className="form_card-body">
              <form onSubmit={handleSubmit}>
                {form_json_fields.map((field) => {
                  if (field.type === 'dropdown') { //TIPO DE CAMPO DROPDOWN
                    return (
                      <div className="form_group" key={'fld' + field.field}>
                        <label htmlFor={field.field}>{field.title}</label>
                        <input
                          type="text"
                          id={field.field}
                          name={field.field}
                          value={selectedDropdownItem?.[field.field]}
                          disabled={true}
                          readOnly={true}
                          className="form-control read-only-input"
                        />
                        <div className="input-group-append">
                          <button
                            id={"btn" + field.field}
                            className="btn btn-outline-primary dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            disabled={(field.key && !enableKeyFields) || (enableKeyFields && location.state?.idSelection && field.field in JSON.parse(location.state?.idSelection)) || uploadDisable || field.blocked}
                          >
                          </button>
                          <div className="dropdown-menu dropdown-menu-end">
                            <input
                              type="text"
                              className="dropdown-item"
                              placeholder="Buscar..."
                              onChange={(e) => handleSearch(e, field.field)} value={searchDropdown[field.field]}
                            />
                            <a
                              className="dropdown-item"
                              // key={"dd" + field.field}
                              href="/#"
                              onClick={(e) => handleSelectDropdown(e, '', field.field, '')}
                            >
                              (Limpiar Selección)
                            </a>
                            {filteredData(field.field)[field.field]?.map((item) =>
                              <a
                                className="dropdown-item"
                                key={"dd" + field.field + item[field.field]}
                                href="/#"
                                value={item[field.field + "value"]}
                                onClick={(e) => handleSelectDropdown(e, item[field.field], field.field, item[field.field + "value"])}
                              >
                                {item[field.field]}
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  }
                  else { //OTROS TIPOS DE CAMPO DISTINTO DE DROPDOWN
                    return (
                      <div className="form_group" key={'fld' + field.field}>
                        <label htmlFor={field.field}>{field.title}</label>
                        <input
                          type={field.type}
                          id={field.field}
                          name={field.field}
                          value={formData[field.field] || ''}
                          onChange={handleChange}
                          onKeyDown={handleOnInput}
                          disabled={(field.key && !enableKeyFields) || (enableKeyFields && location.state?.idSelection && field.field in JSON.parse(location.state?.idSelection)) || field?.dom || uploadDisable || field.blocked}
                          readOnly={(field.key && !enableKeyFields) || (enableKeyFields && location.state?.idSelection && field.field in JSON.parse(location.state?.idSelection)) || field?.dom || field.blocked} // Activo o no basado en enableKeyFields
                          className={`form-control ${(field.key && !enableKeyFields) || (enableKeyFields && location.state?.idSelection && field.field in JSON.parse(location.state?.idSelection)) || field.blocked ? 'read-only-input' : ''}`}
                        />
                      </div>
                    )
                  }
                })}
                <button type="submit" id="btn-submit" className="form_btn form_btn-primary">
                  {sys_texts.form_guardar}
                </button>
              </form>
              {uploading && (
                <div className="mt-3">
                  <ProgressBar now={progress} label={`${progress}%`} />
                </div>
              )}
              {downloading && (
                <div className="mt-3">
                  <ProgressBar now={progressDownload} label={`${progressDownload}%`} />
                </div>
              )}
            </div>
          </div>
        </>
    );
  };

  return FormComponent;
};

export default createFormComponent;