import React from 'react';
import { Routes, Route } from 'react-router-dom';
import createBrowseComponent from '../../../mbr_crud/Browse';
import createFormComponent from '../../../mbr_crud/Form';
import { ConnectionProvider } from '../../../mbr_crud/ConnectionContext';
import {
    browse_json_fields,
    form_json_fields,
    sys_texts,
    crud_texts,
    routes,
    get_all_records,
    delete_record,
    update_record,
    insert_record,
    download_file_resultado,
    download_file_resumen,
    set_vars
} from './crud_define';

const BrowseProcesosSteps = createBrowseComponent({
    browse_json_fields,
    form_json_fields,
    sys_texts,
    crud_texts,
    routes,
    get_all_records,
    delete_record,
});

const FormProcesosSteps = createFormComponent({
    form_json_fields,
    sys_texts,
    crud_texts,
    routes,
    get_all_records,
    update_record,
    insert_record,
    download_file_resultado,
    download_file_resumen
});

const CrudRoutesOpeProcesosSteps = () => (
    <ConnectionProvider get_all_records={get_all_records}
        delete_record={delete_record}
        update_record={update_record}
        insert_record={insert_record}
        download_file_resultado={download_file_resultado}
        download_file_resumen={download_file_resumen}
        set_vars={set_vars}
    >
        <Routes>
            <Route path="/" element={<BrowseProcesosSteps />} />
            <Route path="/resumen" element={<FormProcesosSteps />} />
            <Route path="/detalle" element={<FormProcesosSteps />} />
        </Routes>
    </ConnectionProvider>
);

export default CrudRoutesOpeProcesosSteps;